import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import router from '@/router'
export default function useOrdersList() {
  const ordersListTable = ref([])
  let name = ''
  if (router.currentRoute.name == 'apps-orders-placed-list') {
    name = 'Placed'
  } else if (router.currentRoute.name == 'apps-orders-prepared-list') {
    name = 'Preparing'
  } else if (router.currentRoute.name == 'apps-orders-delivered-list') {
    name = 'Delivered'
  } else if (router.currentRoute.name == 'apps-orders-canceled-list') {
    name = 'Canceled'
  }
  const tableColumns = [
    { text: 'ID#', value: 'order_number' },
    { text: 'Date & Time', value: 'created_at' },
    { text: 'Delivery Time Slot', value: 'time' },
    name != 'Placed' && { text: `Time Moved To ${name}`, value: 'updated_at' },
    { text: 'payment method', value: 'payment_method' },
    { text: 'total price (JOD)', value: 'total_price' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableColumnsForItems = [
    { text: 'Name', value: 'item_name_en', sortable: false },
    // { text: 'Size', value: 'sub_item_name_en', sortable: false },
    { text: 'Qty', value: 'quantity', sortable: false },
    { text: 'Rate', value: 'rate', sortable: false },
    { text: 'Chef Name', value: 'chief_name', sortable: false },
    { text: 'Kitchen', value: 'kitchen_name', sortable: false },
    { text: 'price in (JOD)', value: 'price', sortable: false },
    { text: 'price in (point)', value: 'points', sortable: false },
    { text: 'note', value: 'note', sortable: false },
    { text: 'SUBCategory', value: 'subcategory', sortable: false },
    { text: 'Category', value: 'type', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const timer = ref(null)
  const searchQuery = ref('')
  const first_date = ref('')
  const last_date = ref('')
  const planFilter = ref(null)
  const totalOrdersListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
    q: searchQuery.value,
  })
  const selectedRows = ref([])

  // fetch data
  const fetchOrders = () => {
    options.value.q = searchQuery.value
    options.value.first_date = first_date.value
    options.value.last_date = last_date.value
    store
      .dispatch('app-orders/fetchOrders', {
        q: searchQuery.value,
        options: options.value,
        plan: planFilter.value,
      })
      .then(response => {
        const { filteredData, total } = response.data.data
        ordersListTable.value = filteredData
        totalOrdersListTable.value = total
        console.log('ordersListTable.value', ordersListTable.value)
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.error(error)
      })
  }

  watch([searchQuery, planFilter, options, first_date, last_date], () => {
    clearTimeout(timer.value)
    timer.value = setTimeout(() => {
      loading.value = true
      selectedRows.value = []
      fetchOrders()
    }, 1500)
  })

  return {
    first_date,
    last_date,
    tableColumnsForItems,
    ordersListTable,
    planFilter,
    searchQuery,
    tableColumns,
    totalOrdersListTable,
    loading,
    options,
    selectedRows,
    fetchOrders,
  }
}
