<template>
  <v-row class="item-bio-panel">
    <!-- item profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Details</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2" v-if="itemData.status != null">
              <div class="font-weight-medium me-2 text-capitalize">Order Status :</div>
              <span class="text--secondary">
                <v-chip
                  small
                  label
                  :color="resolveUserStatusVariant(itemData.status)"
                  :class="`v-chip-light-bg ${resolveUserStatusVariant(
                    itemData.status,
                  )}--text font-weight-medium text-capitalize`"
                >
                  {{
                    itemData.status === 0
                      ? 'PENDING'
                      : itemData.status === 1
                      ? 'CONFIRMED'
                      : itemData.status === 2
                      ? 'PREPARING'
                      : itemData.status === 3
                      ? 'DELIVERED'
                      : itemData.status === 4
                      ? 'USER CANCELLED'
                      : itemData.status === 5
                      ? 'ADMIN CANCELLED'
                      : ''
                  }}
                </v-chip>
              </span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2 text-capitalize">Order ID:</span>
              <span class="text--secondary">{{ itemData.order_id }}</span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2 text-capitalize">Customer Name:</span>
              <span class="text--secondary">{{ itemData.user.first_name }} {{ itemData.user.last_name }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2 text-capitalize">Mobile Number:</span>
              <span class="text--secondary">{{ itemData.user.mobile_number }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2 text-capitalize">Date & time:</span>
              <span class="text--secondary">{{ itemData.order_date }} </span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2 text-capitalize">Payment Method:</span>
              <span class="text--secondary">{{ getPayment(itemData.payment_method) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2 text-capitalize">Delivered Date & Time:</span>
              <span class="text--secondary">{{
                itemData.delivered_date ? itemData.delivered_date : 'Still not Delivered'
              }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2" v-if="itemData.note">
              <span class="font-weight-medium me-2 text-capitalize">Note:</span>
              <span class="text--secondary">{{ itemData.note }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2" v-if="itemData.promocode_code">
              <span class="font-weight-medium me-2 text-capitalize">promocode code:</span>
              <span class="text--secondary">{{ itemData.promocode_code }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2 text-capitalize">Discount:</span>
              <span class="text--secondary">{{ itemData.discount }}</span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2 text-capitalize">Delivery fees:</span>
              <span class="text--secondary">{{ itemData.delivery_fees }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2" v-if="itemData.time && itemData.time != 0">
              <span class="font-weight-medium me-2 text-capitalize">Time Slot:</span>
              <span class="text--secondary">{{ itemData.time }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2 text-capitalize">Order rate:</span>
              <span class="text--secondary">{{ itemData.rate != null ? itemData.rate : '___' }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2" v-if="itemData.delivery_rate">
              <span class="font-weight-medium me-2 text-capitalize">Delivery Rate:</span>
              <span class="text--secondary">{{
                itemData.delivery_rate.stars ? itemData.delivery_rate.stars : '___'
              }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2" v-if="itemData.delivery_rate">
              <span class="font-weight-medium me-2 text-capitalize">Delivery Feedback:</span>
              <span class="text--secondary">{{
                itemData.delivery_rate.text ? itemData.delivery_rate.text : '___'
              }}</span>
            </v-list-item>

            <div class="d-flex table-container flex-wrap">
              <div class="table">
                <!-- location info -->
                <table border="" class="mt-6">
                  <thead>
                    <tr align="center">
                      <th style="min-width: 200px" colspan="2">Location Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="px-2">Link</td>
                      <td align="center">
                        <v-btn @click="opedLink(itemData.location)"> Go </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td class="px-2">Title</td>
                      <td align="center">{{ itemData.location_informations.title }}</td>
                    </tr>
                    <tr>
                      <td class="px-2">Neighborhood</td>
                      <td align="center">{{ itemData.location_informations.neighbourhood }}</td>
                    </tr>
                    <tr>
                      <td class="px-2">Street Name</td>
                      <td align="center">{{ itemData.location_informations.street_name }}</td>
                    </tr>
                    <tr>
                      <td class="px-2">Building Number</td>
                      <td align="center">{{ itemData.location_informations.building_number }}</td>
                    </tr>
                    <tr>
                      <td class="px-2">Latitude</td>
                      <td align="center">{{ itemData.location_informations.latitude }}</td>
                    </tr>
                    <tr>
                      <td class="px-2">Longitude</td>
                      <td align="center">{{ itemData.location_informations.longitude }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table">
                <!-- payment info -->
                <table border="" class="mt-6">
                  <thead>
                    <tr align="center">
                      <th colspan="3">Payment Info</th>
                    </tr>
                    <tr>
                      <th></th>
                      <th style="min-width: 100px">JOD</th>
                      <th style="min-width: 100px">Pt</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="px-2">Subtotal</td>
                      <td align="center">
                        {{ itemData.sub_total }}
                      </td>
                      <td align="center">
                        {{ itemData.sub_total_points }}
                      </td>
                    </tr>
                    <tr>
                      <td class="px-2">Delivery Fees</td>
                      <td align="center">{{ itemData.delivery_fees }}</td>
                      <td align="center">{{ itemData.delivery_fees_in_points }}</td>
                    </tr>
                    <tr>
                      <td class="px-2">Total</td>
                      <td align="center">{{ itemData.total_price }}</td>
                      <td align="center">{{ itemData.total_points }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- table -->
            <v-data-table id="table" :headers="tableColumnsForItems" :items="itemData.details_all" class="mt-10">
              <template v-slot:top>
                <v-toolbar rounded short dense color="rgb(104 82 215 / 28%)" flat>
                  <v-toolbar-title class="font-weight-medium me-2 text-capitalize">Items Table</v-toolbar-title>
                </v-toolbar>
              </template>
              <!-- sub_item_name_en -->
              <template #[`item.sub_item_name_en`]="{ item }">
                <div class="d-flex align-center">
                  <span class="text-capitalize">{{ item.sub_item_name_en ? item.sub_item_name_en : '___' }}</span>
                </div>
              </template>

              <!-- Chef Name -->
              <template #[`item.rate`]="{ item }">
                <div class="d-flex align-center">
                  <span class="text-capitalize">{{ item.rate ? item.rate.stars : '___' }}</span>
                </div>
              </template>

              <!-- Chef Name -->
              <template #[`item.chief_name`]="{ item }">
                <div class="d-flex align-center">
                  <span class="text-capitalize">{{ item.chief_name ? item.chief_name : '___' }}</span>
                </div>
              </template>

              <!-- kitchen_name -->
              <template #[`item.kitchen_name`]="{ item }">
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    {{ item.kitchen_name ? item.kitchen_name : '___' }}
                  </div>
                </div>
              </template>

              <!-- note -->
              <template #[`item.note`]="{ item }">
                <div class="d-flex align-center">
                  <span class="text-capitalize">{{ item.note ? item.note : '___' }}</span>
                </div>
              </template>

              <!-- type -->
              <template #[`item.type`]="{ item }">
                <div class="d-flex align-center">
                  <span class="text-capitalize">{{
                    item.type == 1 ? 'Meal' : item.type == 2 ? 'Pre-Order' : '___'
                  }}</span>
                </div>
              </template>
              <!-- subcategory -->
              <template #[`item.subcategory`]="{ item }">
                <div class="d-flex align-center">
                  <span class="text-capitalize">{{
                    item.type == 1
                      ? 'Meal'
                      : item.type == 2
                      ? 'Pre-Order'
                      : !item.kitchen_id && !item.is_add_on
                      ? 'Soft-drink'
                      : !item.kitchen_id && item.is_add_on
                      ? 'Add-ons'
                      : '___'
                  }}</span>
                </div>
              </template>

              <!-- actions -->
              <template #[`item.actions`]="{ item }">
                <button
                  class="v-btn v-btn--outlined theme--dark v-size--small error--text ml-4"
                  @click="canceledItem(item.id)"
                  v-if="item.is_canceled"
                >
                  Canceled
                </button>
                <button
                  v-else-if="
                    itemData.status != 3 &&
                    itemData.status != 4 &&
                    itemData.status != 5 &&
                    itemData.details.length >= 1 &&
                    (item.type == 1 || item.type == 2)
                  "
                  class="v-btn v-btn--outlined theme--dark v-size--small error--text ml-4"
                  @click="deleteItem(item.id)"
                >
                  Cancel
                </button>
                <div v-else>___</div>
              </template>
            </v-data-table>
            <br />
            <v-card-actions
              class="justify-center mt-3"
              v-if="itemData.status != 3 && itemData.status != 4 && itemData.status != 5"
            >
              <v-btn color="error" outlined @click="removeItem()"> Cancel </v-btn>
            </v-card-actions>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <base-dialog width="600" title="Reason of cancellation" :showDialog.sync="showPopUp">
      <template v-slot:content>
        <table border="" class="mt-6">
          <thead>
            <tr align="center">
              <th colspan="3">Reason of cancellation</th>
            </tr>
            <tr>
              <th style="min-width: 150px">Cancelled by</th>
              <th style="min-width: 150px">Name</th>
              <th style="min-width: 200px">Reason</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center" class="px-2">Admin</td>
              <td align="center">
                Admin name
              </td>
              <td align="center">
                Reason of cancellation
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </base-dialog>
  </v-row>
</template>

<script>
import store from '@/store'
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import useOrdersList from '../../orders-list/useOrdersList'
import baseDialog from '@/views/components/baseDialog.vue'

export default {
  components: { baseDialog },
  emits: ['refresh-data'],
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showPopUp: false,
    }
  },
  methods: {
    deleteItem(itemId) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel it!',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
      })
        .then(result => {
          if (result.isConfirmed) {
            const id = this.$route.params.id
            let data = {
              order_id: id,
              reason: document.querySelector('.swal2-input').value,
              items: [itemId],
            }
            console.log('deleteItem => data', data)
            store
              .dispatch('app-orders/cancelOrder', data)
              .then(() => {
                this.$emit('refresh-data')
                Swal.fire('Canceled!', 'entry has been canceled.', 'success')
              })
              .catch(error => {
                Swal.fire({
                  icon: 'error',
                  title: `err`,
                  text: error,
                })
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getPaymentStatus(key) {
      switch (key) {
        case 0:
          return 'PENDING'
        case 1:
          return 'CONFIRMED'
        case 2:
          return 'PREPARING'
        case 3:
          return 'DELIVERED'
        default:
          break
      }
    },
    getPayment(key) {
      switch (key) {
        case '1':
          return 'cache'
        case '2':
          return 'points'
        case '3':
          return 'visa'
        default:
          break
      }
    },
    getOrderStatus(status) {
      switch (status) {
        case 1:
          return 'Placed'
          break
        case 2:
          return 'preparing'
          break
        case 3:
          return 'delivered'
          break
        case 4:
          return 'canceled'
          break
        default:
          return ''
      }
    },
    removeItem() {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel it!',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
      })
        .then(result => {
          if (result.isConfirmed) {
            const id = this.$route.params.id
            let data = {
              order_id: id,
              reason: document.querySelector('.swal2-input').value,
            }

            store
              .dispatch('app-orders/cancelOrder', data)
              .then(() => {
                Swal.fire('Canceled!', 'entry has been canceled.', 'success')
                this.$router.go(-1)
              })
              .catch(error => {
                console.log('error', error.response.data)
                if (error.response.data.message) {
                  Swal.fire({
                    icon: 'error',
                    title: `Error`,
                    text: 'Please add a reason,' + error.response.data.message,
                  })
                }
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    canceledItem() {
      this.showPopUp = true
    },
  },

  setup(props, { emit }) {
    // const { resolveCompanyStatusVariant, resolveCompanyRoleVariant } = useCompaniesList()
    const resolveUserStatusVariant = status => {
      if (status === 0) return 'warning'
      if (status === 1) return 'info'
      if (status === 2) return 'info'
      if (status === 3) return 'success'
      if (status === 4) return 'error'
      if (status === 5) return 'error'
    }
    const isBioDialogOpen = ref(false)
    const { tableColumnsForItems } = useOrdersList()

    const opedLink = link => {
      window.open(link)
    }
    return {
      resolveUserStatusVariant,
      opedLink,
      tableColumnsForItems,
      isBioDialogOpen,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
<style scoped>
.table-container {
  justify-content: center;
  column-gap: 5rem;
}
.table {
  overflow: auto;
}
td {
  min-width: fit-content;
  padding: 0.5rem 1rem;
}
.swal2-html-container {
  white-space: pre-line;
}
</style>
